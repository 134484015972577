
import { Component, Vue } from 'vue-property-decorator'
import { CustomerOrder } from '@/models/index'

@Component({
  name: 'CaretakerOrdersList',
  components: {
    CaretakerOrdersHeader: () => import('./components/CaretakerOrdersHeader.vue'),
    FiltersDrawer: () => import('./components/FiltersDrawer.vue'),
    OrderCardDesktop: () => import('./components/OrderCardDesktop.vue'),
    OrderCardMobile: () => import('./components/OrderCardMobile.vue')
  }
})
export default class extends Vue {
  private orders: CustomerOrder[] = []

  private totalCustomerOrders = 0
  private loading = true
  private windowWidth = window.innerWidth

  private filters: any = {
    number: null,
    email: null,
    deliveryStatus: null,
    dateRange: null,
    programId: null,
    giftCardId: null
  }

  private listQuery: any = {
    page: 1,
    per: 10
  }

  async created() {
    await this.getOrders()

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }

  get mdAndUp() {
    return this.windowWidth >= 690
  }

  private async getOrders(): Promise<void> {
    this.loading = true
    const createdAtGte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange[0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange[1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } =
      await CustomerOrder
        .where({
          number: this.filters.number,
          customerEmail: { prefix: this.filters.email },
          createdAt: { gte: createdAtGte, lte: createdAtLte },
          programId: this.filters.programId,
          giftCardId: this.filters.giftCardId,
          deliveryStatus: this.filters.deliveryStatus
        })
        .where({ paymentStatus: 'paid' })
        .includes(['giftCard', 'customer', 'cardDistributions', { multiChoiceCards: ['multiChoiceGiftCard', 'dotationOperation'] }, { gift: 'recipient' }])
        .page(this.listQuery.page)
        .per(this.listQuery.per)
        .order({ createdAt: 'desc' })
        .stats({ total: 'count' })
        .all()

    this.orders.push(...data)
    this.totalCustomerOrders = meta.stats.total.count
    this.listQuery.page += 1
    this.loading = false
  }

  setFilters(filters: any) {
    this.filters = { ...filters }
    this.refresh()
  }

  private refresh() {
    this.listQuery.page = 1
    this.orders.splice(0, this.orders.length)
    this.getOrders()
  }
}
