
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CustomerOrder } from '@/models/index'

@Component({
  name: 'OrderCardDesktop',
  components: {
    CardDistributionTooltip: () => import('@/components/CardDistributionTooltip/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: CustomerOrder
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private total!: number
}
