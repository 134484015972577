
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard, Program } from '@/models'

@Component({
  name: 'FiltersDrawer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private giftCards: GiftCard[] = []
  private giftCardsLoading = true
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private programs: Program[] = []
  private programsLoading = false

  private showFilters = false

  private filters: any = {
    number: null,
    email: null,
    deliveryStatus: null,
    dateRange: null,
    programId: null,
    giftCardId: null
  }

  private deliveryStatus = [
    {
      label: 'Livré',
      value: 'delivered'
    },
    {
      label: 'Partiellement livré',
      value: 'in_delivery'
    },
    {
      label: 'Non livré',
      value: 'undelivered'
    }
  ]

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null) {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || (typeof this.filters[i] === 'string' && this.filters[i] !== '')) {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  created(): void {
    this.getGiftCards()
    this.getPrograms()
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private async getPrograms() {
    this.programsLoading = true

    const { data } = await Program
      .page(1)
      .per(100)
      .all()

    this.programs = data
    this.programsLoading = false
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.filters.giftCardId = giftCardId
  }
}
